import React from "react";
import '../live/live.css'

function FullScreen() {

    return (
        <section className="fullscreen-container">
        <iframe width="90%" height="90%" src="https://www.youtube.com/embed/JJ3nrnhQuZc?si=bFHTvfy8lI0bwiNz&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </section>
    );
}

export default FullScreen;
