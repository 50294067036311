import React from "react";
import { useNavigate } from 'react-router-dom';
import '../live/live.css'
import Logo from '../../Assets/hub-logo.png'

function Live() {
    const navigate = useNavigate();


    return (
        <section className="center-display">
            <div className="content">
                <div className="line-logo"> <img src={Logo} alt="Logo" /> </div>
                <div className="sub-content">
                    <div id="circle"></div> <p id="text">EM BREVE</p>
                </div>
                <div className="line-title">
                    <h1>Panorama econômico nas Américas</h1>
                </div>
                <p id="par">Participe do webinar "Panorama Econômico nas Américas", um evento online que ocorrerá na terça-feira, 13 de agosto de 2024, às 10:00 AM.



Durante este webinar, especialistas irão discutir as perspectivas econômicas atuais e futuras das Américas, abordando desafios, oportunidades e tendências que estão moldando o cenário econômico na região.</p>
                <a className="acessar" href="https://www.youtube.com/live/l-UUD_UGASg"><p>Acessar agora</p></a>
              
            </div>
        </section>
    );
}

export default Live;
